import { Dialog, Step, StepLabel, Stepper } from "@material-ui/core";
import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CustomModalComponent from "../../../components/custom-modal-component";
import { FinanceContext } from "../../../context/finance-context";
import ScenarioItemContext from "../../../context/scenario-item-context";
import {
  useDocumentListWithURL,
  useLoanSubmission,
  useLoanSubmissionStatus,
} from "../../../hooks/loanApplication.hooks";
import { Edit } from "../../../images";
import { Circle } from "../../../svgs";
import ApplicationLayout from "../components/application-layout";
import ApplicationMobileLayout from "../components/application-mobile-layout";
import UpdateLoanSubmissionButton from "./components/update-loan-submission-button";
import FileList from "../components/file-list";
import { useIsMobile } from "../../../hooks/common.hooks";

const QontoStepIcon = (props) => {
  const { active, completed } = props;
  return (
    <div
      className={classNames("application-process-icon", {
        active: active || completed,
      })}
    >
      <Circle />
    </div>
  );
};

const ApplicationProcess = () => {
  const { selectedScenarioId = "", selectedScenarioDetail = {} } = useContext(
    FinanceContext
  );
  const { setTabIndex, setApplicationStep, setIsUploadDocument } = useContext(
    ScenarioItemContext
  );
  const [isShowing, setIsShowing] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const { opportunityId } = selectedScenarioDetail;
  const [updateLoanContent, setUpdateLoanContent] = useState({
    header: "Update Loan Submission Finance",
    body: "Are you sure you want to update your Loan Submission Finance?",
  });

  const createLoanSubmissionMutation = useLoanSubmission(opportunityId);
  const isMobile = useIsMobile();
  const availableProcessTitles = useMemo(() => {
    if (isMobile) {
      return [
        "Lodged To Lender",
        "Loan Approved",
        "Settlement Booked",
        "Loan Settled",
      ];
    }
    return [
      "Lodgement Preparation",
      "Lodged To Lender",
      "Conditional Approval",
      "Unconditional Approval ",
      "Settlement Booked",
      "Loan Settled",
    ];
  }, [isMobile]);

  const processTitle = useMemo(() => {
    if (activeStep === 1 && !isMobile) {
      return "Waiting For Pre Approved";
    }
    return availableProcessTitles[activeStep - 1];
  }, [activeStep, isMobile]);

  const documentList = useDocumentListWithURL(
    selectedScenarioId,
    opportunityId
  );
  const opportunityItem = useLoanSubmissionStatus(
    selectedScenarioId,
    opportunityId
  );

  const { data: { status: opportunityStatus = [] } = {} } = opportunityItem;

  const updateModelStatus = (status) => {
    setIsShowing(status);
  };

  const handleUploadDocument = () => {
    setIsUploadDocument(true);
    setTabIndex("loan-application");
    setApplicationStep(2);
  };

  const handleUpdateModal = () => {
    setUpdateLoanContent((prevState) => ({
      ...prevState,
      body: "Are you sure you want to update your Loan Submission Finance?",
    }));
    createLoanSubmissionMutation.reset();
    updateModelStatus(true);
  };

  const handleUpdateLoanSubmission = () => {
    createLoanSubmissionMutation.mutate(selectedScenarioId, {
      onSuccess: () => {
        setUpdateLoanContent((prevState) => ({
          ...prevState,
          body: "Update loan submissions finance successfully",
        }));
      },
      onError: () => {
        setUpdateLoanContent((prevState) => ({
          ...prevState,
          body: "Update loan submissions finance failed",
        }));
      },
    });
  };

  const setProgressBarStep = (status, mobileView) => {
    if (mobileView) {
      switch (status) {
        case "Unconditional":
          setActiveStep(2);
          break;
        case "Pending Settlement":
          setActiveStep(3);
          break;
        case "Settled":
          setActiveStep(4);
          break;
        default:
          setActiveStep(1);
          break;
      }
    } else {
      switch (status) {
        case "Lodged":
          setActiveStep(2);
          break;
        case "Conditional":
          setActiveStep(3);
          break;
        case "Unconditional":
          setActiveStep(4);
          break;
        case "Pending Settlement":
          setActiveStep(5);
          break;
        case "Settled":
          setActiveStep(6);
          break;
        default:
          setActiveStep(1);
          break;
      }
    }
  };
  useEffect(() => {
    setProgressBarStep(opportunityStatus, isMobile);
  }, [selectedScenarioId, opportunityStatus, isMobile]);

  return (
    <ApplicationMobileLayout>
      <ApplicationLayout page="application-process">
        {opportunityId ? (
          <div className="application-progress-container">
            <div className="font-weight-bold font-size-lg">{processTitle}</div>
            <div>(Hover for more details)</div>
            <div className="application-process-bar">
              <Stepper activeStep={activeStep}>
                {availableProcessTitles.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="update-loan-submission">
              <UpdateLoanSubmissionButton
                title="Upload More Documents"
                onClick={handleUploadDocument}
              />
              <UpdateLoanSubmissionButton
                title="Update Loan Submission"
                onClick={handleUpdateModal}
              />
            </div>
            {documentList.length ? (
              <div className="mt-5">
                <p className="font-weight-semibold ml-4">Document Name</p>
                <FileList
                  fileList={documentList}
                  selectedScenarioId={selectedScenarioId}
                  opportunityId={opportunityId}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="no-lender-option ">
            Please submit the loan application to see the application process.
          </div>
        )}
        <Dialog
          open={isShowing}
          onClose={() => updateModelStatus(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-modal-container"
        >
          <CustomModalComponent
            header={updateLoanContent.header}
            description={updateLoanContent.body}
            handleCloseModal={() => updateModelStatus(false)}
            state={createLoanSubmissionMutation.isSuccess}
            isProcessing={createLoanSubmissionMutation.isLoading}
            inputFuct={handleUpdateLoanSubmission}
            icon={Edit}
          />
        </Dialog>
      </ApplicationLayout>
    </ApplicationMobileLayout>
  );
};

ApplicationProcess.propTypes = {};

export default ApplicationProcess;
